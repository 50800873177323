<template>
  <div
    id="rfp-overview-status"
    v-loading="!proposalDocument"
    :class="isUploadRenewalRatePass ? 'upload-renewal-on' : ''"
  >
    <div
      v-if="proposalDocument && project"
      id="proposal-info"
    >
      <h1 data-test="employer name header">
        {{ project.employer?.name }}
      </h1>
      <p v-if="products?.length">
        <TfBadge
          :status="stateTextAndClass.status"
          :value="stateTextAndClass.text"
          size="large"
          data-test="opportunity status badge"
        />
        <span
          v-if="dueDate.appendText"
          id="due-date"
          data-test="due date"
          :class="{'text-danger': dueDate.dayDifference < 0}"
          v-text="dueDate.appendText"
        />
      </p>
    </div>

    <ElDialog
      :visible="allProductsSubmittedDialogVisible"
      class="products-submitted-dialog"
      data-test="submitted dialog"
      @close="allProductsSubmittedDialogVisible = false"
    >
      <img :src="productsSubmittedIcon">
      <h2 data-test="dialog header">
        All products have been submitted
        <template v-if="broker.name">
          to {{ broker.name }}
        </template>
        !
      </h2>
      <p>You can continue to refine and track your quote and collaborate with your broker here.</p>
      <AppButton
        icon="
          fa-solid
          fa-comments"
        size="text"
        text="Start a conversation"
        data-test="start conversation"
        @click="onStartAConversationClick"
      />
    </ElDialog>
    <div class="btn-group">
      <AppButton
        v-if="showDealHighlightButton"
        :text="dealHighlightButtonText"
        data-test="open deal highlights editor"
        icon="fa-solid fa-circle-dollar"
        size="text"
        @click="$emit('viewSalesPitch')"
      />
      <TfTooltip
        :options="{
          placement: 'right',
          modifiers: { offset: { offset: '0,10px' } }
        }"
        class="popper-subscribe"
      >
        {{ isWatching
          ? 'Unsubscribe to no longer'
          : 'Subscribe to'
        }} receive email notifications related to this RFP.
        <AppButton
          slot="reference"
          :icon="`fa-solid ${isWatching ? 'fa-eye-slash' : 'fa-eye'}`"
          :is-disabled="isProcessingDocuments"
          :is-loading="isSavingWatch"
          :text="isWatching ? 'Unsubscribe' : 'Subscribe'"
          :type="isWatching ? 'danger' : 'primary'"
          data-test="subscribe to rfp"
          size="text"
          @click="toggleWatch"
        />
      </TfTooltip>
      <TfPopover
        :append-to-body="false"
        :offset="-9"
        data-test="upload options"
        placement="bottom-end"
        popper-class="upload-popover"
        @hide="isUploadDropdownOpen = false"
      >
        <AppButton
          slot="reference"
          :icon="`fa-solid ${isUploadDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`"
          :is-disabled="isProcessingDocuments"
          data-test="open upload menu"
          text="Upload"
          size="text"
          @click="isUploadDropdownOpen = true"
        />
        <ul class="popover-body">
          <li
            id="btn-supplemental-documents"
            data-test="supplemental documents"
            @click="isModalSupplementalDocumentsOpen = true"
          >
            Supplemental documents
          </li>
          <li
            v-if="isCsvQuoteEnabled"
            @click="isModalQuoteFileOpen = true"
          >
            Quote file
          </li>
        </ul>
      </TfPopover>
    </div>
    <ProposalManagerModal
      v-if="proposalDocument && proposals"
      :visible.sync="isModalSupplementalDocumentsOpen"
      :proposals="proposals"
      data-test="proposal manager"
    />
    <QuoteFileModal
      v-if="isModalQuoteFileOpen"
      :visible.sync="isModalQuoteFileOpen"
      data-test="edit quote file"
    />
  </div>
</template>

<script>
  // pinia
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useDealHighlightsStore } from '@/stores/dealHighlights.js';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  // feature flags
  import { salesPitch } from '@/utils/featureFlags.js';
  // Services
  import ServiceProject from '@/services/project.js';
  // Assets
  import productsSubmittedIcon from '@/assets/products-submitted.svg';
  // utils
  import { segmentData } from '@/utils/analytics.js';
  import { Product } from '@watchtowerbenefits/shared-components';
  import { formatSimpleDate, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  // components
  import ProposalManagerModal from '@/components/Modals/ProposalManagerModal.vue';
  import QuoteFileModal from '@/components/Modals/QuoteFileModal/index.vue';
  import {
    inProgressSteps,
  } from '@/utils/product.js';

  /**
   * RFP Overview Status
   *
   * @exports RfpOverview/RfpStatus
   */
  export default {
    name: 'RfpStatus',
    components: { ProposalManagerModal, QuoteFileModal },
    data: () => ({
      inProgressSteps,
      isUploadDropdownOpen: false,
      isModalSupplementalDocumentsOpen: false,
      isModalQuoteFileOpen: false,
      isSavingWatch: false,
      alertWatching: null,
      productsSubmittedIcon,
    }),
    computed: {
      ...mapWritableState(useNotificationsStore, ['allProductsSubmittedDialogVisible']),
      ...mapState(useProjectStore, [
        'autoRenewActivated',
        'broker',
        'csvQuoteEnabled',
        'isRenewalProject',
        'project',
        'projectId',
        'proposalDocument',
        'proposals',
      ]),
      ...mapState(useDealHighlightsStore, {
        dealHighlightId: 'id',
        canSubmitDealHighlight: 'canSubmit',
      }),
      ...mapState(useProductStore, [
        'products',
        'isProcessingDocuments',
        'isUploadRenewalRatePass',
        'isUploadRenewalRatePassOrSmartProposal',
      ]),
      /**
       * Evaluate the isCsvQuoteEnabled feature flag.
       *
       * @returns {boolean}
       */
      isCsvQuoteEnabled() {
        const hasStopLoss = this.products.some((product) => Product.isStopLoss(product));

        return this.csvQuoteEnabled && hasStopLoss;
      },
      /**
       * Gets the state of the salesPitch feature flag.
       *
       * @returns {boolean}
       * @deprecated rff:salesPitch
       */
      ffSalesPitch() {
        return this.$ld.checkFlags(salesPitch);
      },
      /**
       * Determines whether to show the deal highlight button or not in the template.
       *
       * @returns {boolean}
       */
      showDealHighlightButton() {
        // removed this from the operand chain for readability and to make it easy to remove later
        if (!this.ffSalesPitch) {
          return false;
        }

        return !this.isRenewalProject && (this.canSubmitDealHighlight || this.dealHighlightId);
      },
      /**
       * Determines what verb to use for the deal highlight button in the template.
       *
       * @returns {string}
       */
      dealHighlightButtonText() {
        if (!this.canSubmitDealHighlight) {
          return 'View deal highlight';
        }

        if (this.dealHighlightId) {
          return 'Edit deal highlight';
        }

        return 'Add deal highlight';
      },
      /**
       * Returns the class and text for the `state badge` in the subHeader
       *
       * @returns {object}
       */
      dueDate() {
        const dueDate = new Date(this.project.due_date);
        const today = new Date();
        let appendText;

        today.setHours(0, 0, 0, 0);

        const dayDifference = Math.round((dueDate - today) / (1000 * 60 * 60 * 24));

        if (this.products.some(
          (product) => (
            ['not_started', ...this.inProgressSteps].includes(product.state)
            && !this.notSoldToThisCarrier(product.project_product)
          ),
        )
        ) {
          appendText = '(past due)';

          if (dayDifference === 0) {
            appendText = '(Due today)';
          } else if (dayDifference > 0) {
            appendText = ` (${dayDifference} days)`;
          }

          appendText = `Due ${formatSimpleDate(this.project.due_date)} ${appendText}`;
        }

        return { dueDate, dayDifference, appendText };
      },
      /**
       * Evaluate 'is_watching' from this.project to determine styles and unsubscribe or subscribe patch call.
       *
       * @returns {boolean}
       */
      isWatching() {
        return this.project.is_watching;
      },
      /**
       * Returns the class and text for the `state badge` in the subHeader
       *
       * @returns {object}
       */
      stateTextAndClass() {
        const { badge } = this.projectStatusFromProducts(this.products);
        let status = 'notify';

        if (badge === 'Submitted') {
          status = 'success';
        }
        if (badge === 'Incomplete') {
          status = 'warn';
        }
        if (badge === 'Declined') {
          status = 'error';
        }

        return { text: badge, status };
      },
    },
    created() {
      if (this.autoRenewActivated && !this.isUploadRenewalRatePassOrSmartProposal) {
        this.$message({
          showClose: true,
          message: 'Products with the status “In progress” are currently being worked on by ThreeFlow and should not be edited. If you need to replace your previous uploaded files, that will restart the 2 business day processing time.',
          type: 'warning',
          duration: 7000,
        });
      }
    },
    methods: {
      ...mapActions(useProjectStore, ['setProjectInfoParameter']),
      ...mapActions(useProductTableStore, [
        'notSoldToThisCarrier',
        'projectStatusFromProducts',
      ]),
      /**
       * If the user clicks 'start a conversation' in the allProductsSubmittedDialog - we need to close it and open the comments tab
       */
      onStartAConversationClick() {
        this.allProductsSubmittedDialogVisible = false;
        this.$router.push({ name: 'Comments' });
      },

      /**
       * Call the "patchCarrierProjectSubscription" to fire the subscribe or unsubscribe action based on the current project state.
       * Update the new value in the project state within the store and API, then show appropriate $message.
       */
      toggleWatch() {
        const key = 'is_watching';
        const actionType = this.isWatching
          ? 'unsubscribe'
          : 'subscribe';
        const onLoaded = (type = 'success') => {
          const eventTitle = actionType === 'subscribe'
            ? 'Subscribe to opportunity'
            : 'Unsubscribe from opportunity';

          trackSegmentEvent(eventTitle, segmentData());

          let message = `You’ll ${this.isWatching ? 'now' : 'no longer'} receive email notifications related to this RFP.`;

          if (type === 'error') {
            const h = this.$createElement;
            const link = h(
              'a',
              { attrs: { href: 'mailto:support@threeflow.com' } },
              ' contact us ',
            );

            message = h('p', null, [
              h('span', null, 'We are not able to process your request. Please try again or'),
              link,
              h('span', null, 'for help.'),
            ]);
          }

          this.isSavingWatch = false;
          this.alertWatching = this.$message({
            showClose: true,
            message,
            type,
            duration: 5000,
          });
        };

        this.isSavingWatch = true;

        // close any previous messages
        if (this.alertWatching) {
          this.alertWatching.close();
        }
        this.$nextTick(() => {
          ServiceProject.patchCarrierProjectSubscription(
            this.projectId,
            actionType,
            ({ project }) => {
              this.setProjectInfoParameter({ key, value: project[key] });
              onLoaded();
            },
            () => {
              onLoaded('error');
            },
          );
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #rfp-overview-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 40px 30px;
    background: $tf-extra-light-gray-2;

    &.upload-renewal-on {
      background: $tf-white;
    }
  }

  #btn-upload-proposal {
    margin-left: 15px;
  }

  .btn-group {
    > * + * {
      margin-left: 30px;
    }

    :deep(.button-text) {
      font-weight: 700;
    }
  }

  #due-date {
    display: block;

    @media (min-width: 600px) {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .el-alert {
    max-width: 500px;
    margin: 0 20px;
  }

  #proposal-info {
    h1,
    p {
      white-space: nowrap;
    }

    p {
      margin: 0;
      line-height: 1.8;
    }
  }

  .popper-subscribe {
    :deep(.tf-popper.tf-tooltip) {
      width: 180px;
      text-align: left;
    }
  }

  .text-danger {
    font-weight: 700;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    color: $tf-dark-gray;
  }

  :deep(.products-submitted-dialog) {
    text-align: center;

    .el-dialog {
      max-width: 425px;
    }

    .el-dialog__header {
      border-bottom: 0;
    }

    h2 {
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
    }

    .tf-button {
      margin: 0 auto;
    }
  }

  :deep() {
    .upload-popover {
      padding: 0;
    }
  }

  .popover-body {
    li {
      padding: 12px 16px;
      cursor: pointer;

      &:hover,
      &:active {
        background-color: $tf-light-blue;
      }
    }
  }
</style>
