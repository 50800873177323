<template>
  <div>
    <div class="upload-proposal">
      <p
        v-if="!isUploadRenewalRatePass && !isSmartProposal"
        data-test="auto renewal disclaimer"
      >
        <strong data-test="disclaimer text">
          You upload the documents and we do the work!
        </strong>
      </p>
      <AppAlert
        v-if="uploadError"
        :closable="false"
        data-test="upload error alert"
        class="upload-failure-disclaimer"
        type="danger"
        show-icon
      >
        {{ uploadError }}
        If the problem persists,
        <TfCtaEmailSupport
          class="red-contact-support-cta"
          data-test="contact support"
        />.
      </AppAlert>
      <p
        v-if="isUploadRenewalRatePassOrSmartProposal"
        data-test="smart upload description"
      >
        ThreeFlow will find rate
        <template v-if="isSmartProposal || threeflowAssistSupported">
          and plan design
        </template>
        information from your uploaded PDF document and enter it in your quote.
        <br>
        <AppButton
          data-test="upload pdf help link"
          size="text"
          tag="a"
          text="How to save my .doc or .xls as a PDF."
          href="https://support.threeflow.com/en/articles/9179732-how-do-i-save-a-document-as-a-pdf"
          target="_blank"
        />
      </p>
      <ElUpload
        ref="upload"
        data-test="drag and drop files"
        :action="`${apiUrl}/v1/carrier_portal/documents/${proposalDocumentId}/upload_sources`"
        :headers="{'X-API-AUTHTOKEN': `${authToken}`}"
        :on-success="onUploadSuccess"
        :before-upload="onBeforeUpload"
        :on-error="onUploadError"
        :on-progress="onProgressUpdate"
        :accept="acceptableFileExtensions.join(',')"
        :show-file-list="false"
        drag
        name="files[]"
        multiple
      >
        <img
          class="el-icon-upload"
          :src="uploadFilesIcon"
          alt="No proposals icon"
        >
        <div class="el-upload__text">
          Drop file here <br> or <em>click to upload</em>
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
        >
          <template v-if="isUploadRenewalRatePassOrSmartProposal">
            Other documents accepted (.doc, .xls, .rtf files) will be added as supplemental documents.
            File size is limited to 20MB.
          </template>
          <template v-else>
            Accepted file types PDF, DOC, Excel, RTF files. 20MB limit.
          </template>
        </div>
      </ElUpload>
    </div>

    <div class="manage-documents">
      <div class="manage-documents-heading">
        <h4>Manage documents</h4>
        <AppButton
          :is-loading="isLoading"
          :is-disabled="!clonedProposals.length"
          data-test="download all proposal documents"
          icon="fa-solid fa-file-arrow-down"
          size="text"
          text="Download all"
          @click="downloadAllDocuments"
        />
      </div>
      <p
        v-if="uploadError"
        data-test="document not uploaded warning"
        class="red-error-text"
      >
        Document not uploaded
      </p>
      <p v-if="!clonedProposals.length && !isUploading && !uploadError">
        No documents uploaded
      </p>
      <ul
        v-else
        class="file-list"
      >
        <li
          v-for="file in uploadingFiles"
          :key="file.uploadingFile.uid"
        >
          <span class="name">
            {{ file.name }}
          </span>
          <ElProgress
            :show-text="false"
            :stroke-width="18"
            :percentage="file.progress"
          />
          <AppButton
            class="cancel-upload-button"
            data-test="cancel file upload"
            icon="fa-solid fa-xmark"
            size="text-small"
            text="Cancel upload"
            type="danger"
            @click="onCancelUploadClick(file)"
          />
        </li>
        <li
          v-for="proposal in clonedProposals"
          :key="proposal.id"
        >
          <span
            v-if="!proposal.editing"
            class="name"
          >
            {{ proposal.name }}
          </span>
          <span
            v-else
            class="edit-container"
          >
            <form @submit.prevent="onInputEnter(proposal)">
              <ElInput
                v-model="proposal.inputName"
              >
                <template slot="append">.{{ proposal.extension }}</template>
              </ElInput>
            </form>
          </span>
          <div class="edit-file-btn-group">
            <template v-if="proposal.editing">
              <AppButton
                class="cancel-button"
                data-test="cancel edit filename"
                icon="fa-solid fa-xmark"
                size="text-small"
                text="Cancel changes"
                type="danger"
                @click="onCancelEdit(proposal)"
              />
              <AppButton
                data-test="save filename changes"
                icon="fa-solid fa-check"
                size="text-small"
                text="Save changes"
                type="success"
                @click="onSaveChanges(proposal)"
              />
            </template>
            <template v-else>
              <AppButton
                :is-disabled="isSubmitting"
                data-test="edit filename"
                icon="fa-solid fa-pencil"
                size="text-small"
                text="Edit"
                @click="onEditClick(proposal)"
              />
              <AppButton
                :is-disabled="isSubmitting"
                data-test="delete file"
                icon="fa-solid fa-trash-can"
                size="text-small"
                text="Delete file"
                @click="handleDeleteClick(proposal)"
              />
            </template>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="!isUploadRenewalRatePass && !isSmartProposal"
      id="modal-terms-disclaimer"
    >
      <span>
        By submitting, you agree to our
        <AppButton
          data-test="open terms modal"
          size="text"
          text="terms"
          @click="showTermsModal = !showTermsModal"
        />.
      </span>
    </div>
    <div class="btn-group">
      <AppButton
        :is-disabled="disableSubmitButton"
        data-test="submit renewal"
        size="large"
        :text="confirmUploadDocumentsCtaText"
        @click="submitDocument"
      />
    </div>
    <TermsModal
      v-if="showTermsModal"
      :visible.sync="showTermsModal"
      data-test="terms modal"
      @closeDialog="showTermsModal = false"
    />
    <NonPdfModal
      v-if="showNonPdfModal"
      :visible.sync="showNonPdfModal"
      @submitRenewal="submitDocument"
    />
  </div>
</template>

<script>
  import Vue from 'vue';
  import DocumentsService, { startSmartProposal } from '@/services/documents.js';
  import Interceptor from '@/services/interceptor.js';
  import ProposalService from '@/services/proposal.js';
  import uploadFilesIcon from '@/assets/upload-files-icon.svg';

  import TermsModal from '@/components/Modals/TermsModal.vue';
  // Pinia
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';

  import {
    reusableAcceptableUploadFileTypes,
    reusableAcceptableUploadFileExtensions,
    trackSegmentEvent,
    getCookie,
  } from '@watchtowerbenefits/es-utils-public';
  import { captureException, addBreadcrumb } from '@sentry/vue';
  import { segmentData } from '@/utils/analytics.js';
  import NonPdfModal from '@/components/Modals/StartOrUpdateRenewalModal/NonPdfModal.vue';
  import { config } from '@/utils/config.js';
  import { isValidFileSize } from '@/utils/file.js';

  const apiUrl = config.VUE_APP_API_URL;
  const authKey = 'X-API-AUTHTOKEN';
  const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;
  const apiConfig = {
    apiUrl,
    cookieNamespace: cookieNameSpace,
  };

  /**
   * Upload, edit, & delete files for rate pass renewal.
   *
   * @exports Modals/StartOrUpdateRenewalModal/FileUpload
   */
  export default {
    name: 'FileUpload',
    components: {
      TermsModal,
      NonPdfModal,
    },
    props: {
      productIds: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        apiUrl,
        authToken: null,
        clonedProposals: [],
        extensionErrorMessage: 'Please upload .pdf, .doc, .xls or .rtf files.',
        fetchError: false,
        headers: {
          [authKey]: null,
        },
        isDeleting: false,
        isLoading: false,
        isSubmitting: false,
        showTermsModal: false,
        sourceService: this.$TF.SourceService,
        uploadError: '',
        uploadFilesIcon,
        uploadingFiles: {},
        showNonPdfModal: false,
      };
    },
    computed: {
      ...mapWritableState(useProductStore, [
        'threeflowAssistProductSnapshot',
      ]),
      ...mapState(useCarrierInfoStore, { carrierId: 'id' }),
      ...mapState(useAccountStore, ['userInfo']),
      ...mapState(useProjectStore, [
        'broker',
        'employerName',
        'proposalDocumentId',
        'proposals',
      ]),
      ...mapState(useProductStore, [
        'isUploadRenewalRatePass',
        'isSmartProposal',
        'isUploadRenewalRatePassOrSmartProposal',
        'products',
        'isAllNewCoverage',
        'isMixOfNewAndRenewingCoverage',
        'threeflowAssistSupported',
        'threeflowAssistSupportedNoAlts',
        'someThreeflowAssistProducts',
        'diveUnsupportedProductTypes',
      ]),
      /**
       * Is true when files are uploading.
       *
       * @returns {boolean}
       */
      isUploading() {
        return !!Object.keys(this.uploadingFiles).length;
      },
      /**
       * Disables submit button if no proposals or already submitting, is uploading a file, or deleting a file
       *
       * @returns {boolean}
       */
      disableSubmitButton() {
        if (!this.proposals.length || this.isSubmitting || this.isUploading || this.isDeleting) return true;

        return this.clonedProposals
          .map(({ dive_rate_extraction_ids: diveRateExtractionIds }) => diveRateExtractionIds || [])
          .every((arrayOfDiveProcessedIds) => arrayOfDiveProcessedIds.length > 0);
      },
      /**
       * Compute an array with both upper and lowercase version of allowed extensions
       *
       * @returns {Array}
       */
      acceptableFileExtensions() {
        return reusableAcceptableUploadFileExtensions().concat(
          reusableAcceptableUploadFileExtensions().map((extension) => extension.toUpperCase()),
        );
      },
      /**
       * Return the correct text to display in the confirm upload documents button.
       *
       * @returns {Array}
       */
      confirmUploadDocumentsCtaText() {
        let ctaText = (this.isUploadRenewalRatePassOrSmartProposal) ? 'Send to ThreeFlow' : 'Next';

        if ((this.isUploadRenewalRatePassOrSmartProposal) && !this.proposals.length) {
          ctaText = 'Upload documents to send to ThreeFlow';
        }

        return ctaText;
      },
    },
    /**
     * create a drag area listener in order to run onDrop function on listener call
     */
    mounted() {
      if (this.checkUploadDragger()) {
        this.$refs.upload.$el
          .querySelector('.el-upload-dragger')
          .addEventListener('drop', this.onDrop);
      }
    },
    created() {
      // grab the cookienamespace and the auth token to pass to the upload component

      this.authToken = getCookie(`${cookieNameSpace}-auth-token`);
      this.headers[authKey] = this.authToken;

      this.clonedProposals = structuredClone(this.proposals) || [];

      this.clonedProposals.forEach((proposal) => {
        // eslint-disable-next-line no-unused-vars
        const scopedProposal = proposal;

        this.splitProposalFileName(scopedProposal);
      });
    },
    /**
     * remove all listeners and upload errors
     */
    beforeDestroy() {
      if (this.checkUploadDragger()) {
        this.$refs.upload.$el
          .querySelector('.el-upload-dragger')
          .removeEventListener('drop', this.onDrop);
      }

      this.uploadError = '';
    },
    methods: {
      ...mapActions(useProjectStore, [
        'updateProposal',
        'removeProposal',
        'setProposals',
      ]),
      /**
       * Checks for .el-upload-dragger
       *
       * @returns {boolean}
       */
      checkUploadDragger() {
        return this.$refs.upload && this.$refs.upload.$el.querySelector('.el-upload-dragger');
      },
      /**
       * Split the proposals name into two sections so we can show the extension seperately
       *
       * @param {object} proposal
       * @returns {object}
       */
      splitProposalFileName(proposal) {
        const scopedProposal = proposal;

        scopedProposal.editing = false;
        // editing flags whether or not the user is editing the file name
        if (scopedProposal.name) {
          // we split the files name into parts so the user can edit the name but not the extension
          const extensionArray = scopedProposal.name.split('.');

          scopedProposal.extension = extensionArray[extensionArray.length - 1];
          extensionArray.splice(extensionArray.length - 1, 1);
          scopedProposal.inputName = extensionArray.join('.');
        } else {
          scopedProposal.inputName = '';
          scopedProposal.extension = '';
        }

        return scopedProposal;
      },

      /**
       * Manually set the progress attribute so we can update the progress elements on the page
       *
       * @param {object} event
       * @param {object} file
       */
      onProgressUpdate(event, file) {
        let progress = event.percent;

        progress -= (progress * 0.03);
        // show the progress component as %3 less then it really is (this way it doesn't look like it hangs at 100%)
        this.uploadingFiles[file.uid].progress = Math.floor(progress);
      },

      /**
       * Save the name of a file, call the proposal service and update the pinia store
       *
       * @param {object} file
       */
      onSaveChanges(file) {
        const index = this.clonedProposals.findIndex((proposal) => proposal.id === file.id);
        const proposal = JSON.parse(JSON.stringify(this.clonedProposals[index]));

        proposal.name = `${proposal.inputName}.${proposal.extension}`;

        ProposalService.updateProposalName(proposal.id, proposal.name);
        proposal.editing = false;
        this.clonedProposals.splice(index, 1, proposal);
        this.updateProposal(proposal);
      },

      /**
       * Call onSaveChanges if the user hits enter while editing a file name
       *
       * @param {object} file
       */
      onInputEnter(file) {
        this.onSaveChanges(file);
      },

      /**
       * Cancel the editing of a file's name
       *
       * @param {object} file
       */
      onCancelEdit(file) {
        const index = this.clonedProposals.findIndex(({ id }) => id === file.id);
        const proposal = JSON.parse(JSON.stringify(this.clonedProposals[index]));
        const extensionArray = proposal.name.split('.');

        proposal.extension = extensionArray[extensionArray.length - 1];
        extensionArray.splice(extensionArray.length - 1, 1);
        proposal.inputName = extensionArray.join('.');

        proposal.editing = false;
        this.clonedProposals.splice(index, 1, proposal);
      },

      /**
       * Cancel the editing of a file's name
       *
       * @param {object} file
       */
      onEditClick(file) {
        const index = this.clonedProposals.findIndex(({ id }) => id === file.id);
        const scopedFile = file;

        scopedFile.editing = true;
        this.clonedProposals.splice(index, 1, scopedFile);
      },

      /**
       * Cancel an upload in progress
       *
       * @param {object} file
       */
      onCancelUploadClick(file) {
        const ref = this.$refs.upload;

        ref.abort(file.uploadingFile);
        Vue.delete(this.uploadingFiles, file.uploadingFile.uid);
      },
      /**
       * Call the Proposal Service and delete this file from the server then update the pinia store
       *
       * @param {object} file
       * @param {string|number} file.id
       */
      async handleDeleteClick({ id }) {
        this.isDeleting = true;
        const index = this.clonedProposals.findIndex((proposal) => proposal.id === id);

        try {
          await this.sourceService.deleteSource(id, 'carrier_portal', apiConfig);
          this.removeProposal(id);
          this.clonedProposals.splice(index, 1);
        } catch {
          this.$message({
            message: 'There was an error deleting the document. Please try again.',
            type: 'error',
          });
        } finally {
          this.isDeleting = false;
        }
      },

      /**
       * On Upload Success we remove the file from the uploadFiles object and update the proposals array in the pinia store
       *
       * @param {object} response
       * @param {object} file
       */
      onUploadSuccess(response, file) {
        let proposal = response.successfully_uploaded_sources[0];

        proposal = this.splitProposalFileName(proposal);
        this.clonedProposals.unshift(proposal);
        Vue.delete(this.uploadingFiles, file.uid);
        this.setProposals(response.sources);
      },

      /**
       * When a file is dropped, element automatically filters out unacceptable file names and checks if the file is too large
       *
       * @param {Event} e
       */
      onDrop(e) {
        const { files } = e.dataTransfer;

        Object.keys(files).forEach((item) => {
          const file = files[item];
          const extensionArray = file.name.split('.');
          const extension = extensionArray[extensionArray.length - 1];

          if (!isValidFileSize(file)) {
            this.uploadError = 'File too large. Uploads must be 20MB or less.';
          } else if (file.raw && file.raw.type) {
            if (!reusableAcceptableUploadFileTypes().includes(file.raw.type)) {
              this.uploadError = this.extensionErrorMessage;
            }
          } else if (!this.acceptableFileExtensions.includes(`.${extension}`)) {
            this.uploadError = this.extensionErrorMessage;
          }
        });
      },

      /**
       * If there is an error on upload we do a combination of the following:
       * Sign the user out (on 401), Throw a Sentry Error, Display a generic error message to the user
       *
       * @param {string} msg
       * @param {object} file
       */
      onUploadError(msg, file) {
        const message = 'Upload proposal error';

        if (msg.status === 401) {
          Interceptor.inactiveLogout();
        }

        addBreadcrumb({
          data: {
            msg,
            file,
          },
          level: 'info',
          message,
        });
        captureException(message);

        this.uploadError = 'Upload failed. Please try again.';
      },

      /**
       * Before we send the file to the server we do some checks on the file extension/type and size
       *
       * @param {object} file
       * @returns {boolean}
       */
      onBeforeUpload(file) {
        let valid = false;

        if (this.headers[authKey] !== getCookie(`${cookieNameSpace}-auth-token`)) {
          Interceptor.inactiveLogout();

          return valid;
        }

        const [, extension] = file.name.split('.');

        if (!isValidFileSize(file)) {
          this.uploadError = 'File too large. Uploads must be 20MB or less.';
        } else {
          valid = file.type
            ? reusableAcceptableUploadFileTypes().includes(file.type)
            : this.acceptableFileExtensions.includes(`.${extension}`);
          this.uploadError = this.extensionErrorMessage;
        }

        if (valid) {
          const { name, uid } = file;
          const fileObject = {
            name,
            uploadingFile: file,
            progress: 0,
          };

          this.uploadError = '';

          this.$set(this.uploadingFiles, uid, fileObject);
          trackSegmentEvent('Upload documents', segmentData());
          if (file.type !== 'application/pdf') {
            this.showNonPdfModal = true;
          }
        }

        return valid;
      },
      async submitDocument() {
        const proposalOrRenewal = this.isUploadRenewalRatePass ? 'renewal' : 'proposal';

        try {
          if (this.isUploadRenewalRatePass) {
            this.isSubmitting = true;
            await DocumentsService.startRenewal(this.proposalDocumentId, {
              product_ids: this.productIds,
            });
          }

          if (this.isSmartProposal) {
            this.isSubmitting = true;
            this.threeflowAssistProductSnapshot = [...this.diveUnsupportedProductTypes];
            await startSmartProposal(this.proposalDocumentId);

            this.$emit('submitDocument', this.showThreeFlowAssistModal());
          }

          if (!this.isUploadRenewalRatePass && !this.isSmartProposal) {
            this.$message({
              message: `
              You've successfully submitted ${this.employerName} to ${this.broker.name} for renewal.
              Your renewal will be ready to review in 2 business days.
            `,
              type: 'success',
            });
          }

          // Send out a segment event in order to track auto-renewals or proposals
          // this is the first event of two necessary to track auto-renewals
          trackSegmentEvent(`Submit auto-${proposalOrRenewal}`, {
            project_id: +this.$route.params.projectId,
            carrier_id: this.carrierId,
            user_carrier_id: this.userInfo.id,
          });
          this.$emit('refreshAndClose');
        } catch {
          this.isSubmitting = false;
          this.$message({
            message: `Not all of the products you selected for ${proposalOrRenewal} were successfully started.`,
            type: 'error',
          });
          this.$emit('refreshAndClose');
        }
      },
      /**
       * Downloads all files that have been uploaded for this project
       *
       */
      async downloadAllDocuments() {
        const filename = `${this.proposalDocumentId}_proposals.zip`;

        this.isLoading = true;
        try {
          const data = await DocumentsService.getAllDocumentSources(this.proposalDocumentId);
          const u8 = new Uint8Array(data);
          const blob = new Blob([u8], { type: 'application/zip' });
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error downloading the documents zipfile, please try again.',
            type: 'error',
            duration: 30000,
          });
        } finally {
          this.isLoading = false;
        }
      },
      /**
       * Checks if the project has any products that can be sent to ThreeFlow Assist
       *
       * @returns {boolean}
       */
      showThreeFlowAssistModal() {
        return (this.isAllNewCoverage || this.isMixOfNewAndRenewingCoverage)
          ? this.diveUnsupportedProductTypes.length > 0
          : false;
      },
    },
  };
</script>
<style scoped lang="scss">
.upload-proposal {
  padding-bottom: 40px;
  color: var(--tf-gray-dark);

  :deep() {
    .el-upload,
    .el-upload-dragger {
      width: 100%;
    }
  }

  p {
    margin: 15px  0 30px;
  }
}

.upload-failure-disclaimer {
  margin: 20px 0 !important; // stylelint-disable-line
}

.manage-documents {
  border-bottom: 1px solid var(--tf-gray-light-medium);
  padding-bottom: 16px;
  color: var(--tf-gray-dark);

  .red-error-text {
    color: var((--tf-danger));
  }
}

.manage-documents-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--tf-gray-light-medium);
  padding-bottom: 20px;

  h4 {
    margin: 0;
  }
}

.download-arrow {
  margin-left: 7px;
}

li {
  display: flex;
  justify-content: space-between;
  min-height: 26px;
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px dotted var(--tf-gray-light-medium);

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.name {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.edit-container {
  flex-grow: 1;
  margin-right: 10px;

  .el-input {
    max-width: 417px;
  }

  @media (max-width: 550px) {
    :deep(.el-input-group__append) {
      display: none;
    }
  }
}

.edit-file-btn-group {
  display: flex;
  justify-content: center;
}

button {
  outline: none;
}

.el-upload__tip {
  font-size: 14px;
  line-height: 18px;
  color: var(--tf-gray-dark);
  text-align: left;
}

.el-progress {
  width: 137px;
  margin-right: 24px;
  align-self: center;
}

.done-button-container {
  display: flex;
  align-items: center;
  padding: 40px 0 24px;
}

#modal-terms-disclaimer {
  flex: 1;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  font-size: 14px;
  margin-right: 55px;
}

a.red-contact-support-cta {
  display: inline;
  margin-right: -3px;

  :deep(.button-text) {
    color: var(--tf-danger);
  }
}
</style>
