<template>
  <header
    :class="{
      'is-plan-summary' : isPlanSummary,
      'upload-renewal-on': isUploadRenewalRatePass
    }"
  >
    <div>
      <h3>
        <span>{{ container.name }}</span>
        <slot name="btnSeparateClasses" />
      </h3>
      <slot name="description" />
      <div
        v-if="showPlanTypeSelect"
      >
        <ElSelect
          v-model="selectedTierGroupId"
          :disabled="!active || $route.meta.readonly"
          placeholder="Select plan type"
          data-test="select plan type"
        >
          <ElOption
            v-for="tierGroup in container.tierGroups"
            :key="tierGroup.id"
            :label="tierGroup.name"
            :value="tierGroup.id"
            :data-test="`select plan type ${tierGroup.name}`"
          />
        </ElSelect>
        <p
          v-if="errorPlanType"
          class="text-error"
          v-text="errorPlanType"
        />
      </div>
      <slot name="policyIds" />
    </div>
    <div
      class="btn-group"
    >
      <slot name="btnCopyInforce" />
      <div
        v-if="!isUploadRenewalRatePass && !isPlanSummary"
        class="btn-expansion"
        @click="$emit('toggle')"
      >
        <AppIcon
          icon="fa-solid fa-chevron-right"
          class="btn-expansion-icon"
          size="2xl"
        />
      </div>
    </div>
  </header>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  /**
   * Container Header
   *
   * @exports DataEntry/ContainerHeader
   */
  export default {
    name: 'ContainerHeader',
    inject: [
      'isPlanSummary',
    ],
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      container: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data: () => ({
      errorPlanType: null,
    }),
    computed: {
      ...mapState(useProductStore, [
        'isUploadRenewalRatePass',
        'currentProduct',
        'productId',
      ]),
      /**
       * Show the plan type select control
       *
       * @returns {boolean}
       */
      showPlanTypeSelect() {
        return !this.isPlanSummary
          && this.currentProduct
          && /dental/i.test(this.currentProduct.product_type_name);
      },
      /**
       * The Tier Group name based on the selected tier group ID for Low Plans
       *
       * @returns {object}
       */
      selectedTierGroup() {
        return this.container.tierGroups.find(
          (tierGroup) => tierGroup.id === this.selectedTierGroupId,
        );
      },
      selectedTierGroupId: {
        /**
         * The Tier Group ID for Low Plans
         *
         * @returns {number}
         */
        get() {
          return this.container.tierGroupId;
        },
        /**
         * sets the actionName on new value
         *
         * @param {string|number} value
         */
        set(value) {
          let actionName = 'patchRateEntryPlanValueTierGroup';

          if (['PlanDesign', 'PlanDesignReview'].includes(this.$route.name)) {
            actionName = 'patchPlanDesignPlanValueTierGroup';
          }

          // Reset any error messaging
          this.errorPlanType = null;
          try {
            this[actionName]({
              containerId: this.container.id,
              productId: this.productId,
              tierGroupId: value,
            });
          } catch (error) {
            // There is no status text coming from the backend on this so putting this in for now.
            this.errorPlanType = error.status === 500
              ? 'Your request did not go through. Please select a new plan type to try again.'
              : error.statusText;
          }
        },
      },
    },
    methods: {
      ...mapActions(usePlanDesignStore, [
        'patchPlanDesignPlanValueTierGroup',
      ]),
      ...mapActions(useRateEntryStore, ['patchRateEntryPlanValueTierGroup']),
    },
  };
</script>

<style lang="scss" scoped>
  header {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    padding: 30px 116px 30px 30px;
    border-bottom: 1px solid $tf-light-gray;
    background-color: $tf-extra-light-gray-2;

    .dialog-plan-summary & {
      padding: 0 0 30px;
      border: none;
      background-color: transparent;
    }

    &.upload-renewal-on {
      background-color: $tf-white;
      margin: 0 32px;
      border: none;
    }
  }

  .btn-group {
    margin-left: auto;
  }

  .btn-expansion {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $tf-medium-gray;
    cursor: pointer;

    .active & {
      .btn-expansion-icon {
        transform: rotate(90deg);
      }
    }

    .btn-expansion-icon {
      transition: all .25s;
      transform: rotate(-90deg);
    }
  }

  h3 {
    font-size: 20px;

    span {
      display: inline-block;
      margin-right: 15px;
    }

    .is-plan-summary & {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  /* Dialog styles */
  :deep() .el-dialog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Needs !important to override higher specificity from dialog.scss */
    // stylelint-disable-next-line declaration-no-important
    padding: 10px 20px !important;
    border-bottom: 1px solid $tf-blue;

    &:before,
    &:after {
      display: none;
    }

    .el-dialog__title {
      font-size: 20px;
      font-weight: 600;
      color: $tf-blue;
    }
  }

  :deep(.el-dialog__headerbtn .el-dialog__close) {
    color: $tf-medium-gray;
    font-weight: 700;
  }

  :deep(.icon-chevron-right) {
    svg {
      margin-left: 8px;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  #class-descriptions {
    p {
      margin: {
        top: 0;
        bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
