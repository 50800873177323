<template>
  <section :class="{ active : active }">
    <ContainerHeader
      v-if="planDesignIsPlan"
      :active="active"
      :container="container"
      data-test="container header"
      @toggle="active = !active"
    />
    <div v-show="active">
      <div class="container-attributes">
        <AppAlert
          v-if="!isPlanSummary && !reviewAlertDismissed && warningMessage"
          :closable="true"
          :title="warningMessage"
          data-test="warning message"
          class="warning-message"
          type="info"
          show-icon
          @close="$emit('dismissAlert')"
        />

        <TableHeader
          v-if="!isPlanSummary"
          :readonly="$route.meta.readonly"
          data-test="table header"
        >
          <!-- Title Slot -->
          <template #title>
            <template v-if="isStopLoss">
              <!-- Only show `New Coverage` for base products stop loss. -->
              {{ isNewCoverage && !currentProduct.project_product.alternative
                ? 'New coverage'
                : $options.filters.capitalize(documentType)
              }}
            </template>
            <template v-else>
              {{ isNewCoverage
                ? 'New coverage'
                : $options.filters.capitalize(documentType)
              }}
            </template>
          </template>
          <template #highlightSource>
            <div
              v-if="isSmartProposal"
              class="highlight-dropdown"
              data-test="highlight dropdown"
            >
              <label
                class="select-label"
                for="source-select"
              >
                Highlight
              </label>
              <ElSelect
                id="source-select"
                v-model="selectedSource"
                :disabled="sourceOptions.length === 0"
                data-test="source select"
                clearable
                placeholder="Select to highlight"
              >
                <ElOption
                  v-for="{ value, label } in sourceOptions"
                  :key="value"
                  :label="label"
                  :value="value"
                  :data-test="label.toLowerCase()"
                />
              </ElSelect>
            </div>
          </template>
          <template #copyFrom>
            <ElFormItem
              v-if="isStopLoss && completedStopLossOptions.length"
              class="select-copy-from"
              label="Copy from previously submitted quote:"
              label-width="300px"
            >
              <ElSelect
                v-model="sourceId"
                data-test="copy from select"
                class="quote-select"
                placeholder="Select quote"
                @change="copyPlanDesign"
              >
                <ElOption
                  v-for="product in completedStopLossOptions"
                  :key="product.project_product.label"
                  :label="product.project_product.label"
                  :value="product.id"
                />
              </ElSelect>
            </ElFormItem>
          </template>
          <!-- Checkbox Slot (when editing) -->
          <template
            #showDeviations
          >
            <div
              v-if="isUploadRenewalRatePassOrSmartProposal"
              :class="[
                isSmartProposal ? 'lower-button': 'save-default-values-btn',
              ]"
            >
              <ElTooltip
                effect="light"
                placement="top"
              >
                <div
                  slot="content"
                  data-test="save plan design preferences tooltip content"
                >
                  Saving plan design preferences will apply this plan design
                  to missing values on future proposals for this product.
                </div>

                <AppButton
                  :is-disabled="!canSavePredefinedValues"
                  size="text"
                  data-test="submit user default values"
                  icon="fa-regular fa-circle-info"
                  text="Save plan design preferences"
                  type="success"
                  @click="$emit('savePreferences')"
                />
              </ElTooltip>
            </div>
            <ElCheckbox
              v-if="!isRenewalProduct && !isSmartProposal"
              v-model="showDeviations"
              data-test="toggle deviations"
            >
              Highlight deviations
            </ElCheckbox>
          </template>
          <!-- Edit Plan Design Slot (when reviewing) -->
          <template #btnEdit>
            <RouterLink :to="{ name: 'PlanDesign' }">
              <AppButton
                icon="fa-solid fa-pencil"
                size="text"
                text="Edit plan design"
              />
            </RouterLink>
          </template>
        </TableHeader>
        <div v-show="active">
          <PlanDesignTable />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { computed } from 'vue';
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import useContainerStore from '@/stores/planDesign/containers.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import ContainerHeader from '@/components/DataEntry/ContainerHeader.vue';
  import TableHeader from '@/components/DataEntry/TableHeader.vue';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import { sourceMap } from '@/utils/planDesignValues.js';
  import PlanDesignTable from './PlanDesignTable.vue';

  /**
   * Plan Design Container
   *
   * @exports PlanDesign/PlanDesignContainer
   */
  export default {
    name: 'PlanDesignContainer',
    components: { ContainerHeader, PlanDesignTable, TableHeader },
    /**
     * Provides reactive containerId, subtypeColspan, and selectedSource.
     *
     * @returns {object}
     */
    provide() {
      return {
        containerId: computed(() => this.containerId),
        subtypeColspan: computed(() => this.subtypeColspan),
        selectedSource: computed(() => this.selectedSource),
      };
    },
    inject: [
      'isPlanSummary',
    ],
    props: {
      container: {
        type: Object,
        default: () => ({}),
      },
      warningMessage: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        active: true,
        sourceId: null,
        selectedSource: '',
      };
    },
    computed: {
      ...mapWritableState(useContainerStore, ['highlightedMap']),
      ...mapState(useProjectStore, ['projectId', 'employerName', 'proposalDocumentId']),
      ...mapState(useUserPreferredPdvsStore, ['userPreferredPlanDesignValues']),
      ...mapState(useProductStore, [
        'currentProduct',
        'documentType',
        'isNewCoverage',
        'isRenewalProduct',
        'productId',
        'isStopLoss',
        'productState',
        'products',
        'completedStopLossProducts',
        'isUploadRenewalRatePassOrSmartProposal',
        'isSmartProposal',
      ]),
      ...mapState(useNotificationsStore, ['reviewAlertDismissed']),
      ...mapState(usePlanDesignStore, [
        'planDesignIsPlan',
        'subtypeColspans',
        'info',
        'uniqueSources',
      ]),
      /**
       * Returns set of only available source options for select.
       *
       * @returns {Array}
       */
      sourceOptions() {
        return this.uniqueSources.map((source) => ({
          value: source,
          label: sourceMap[source],
        }));
      },
      /**
       * Returns all of the complete stop loss product that are not the current product.
       *
       * @returns {Array}
       */
      completedStopLossOptions() {
        return this.completedStopLossProducts.filter(({ id }) => id !== this.productId);
      },
      /**
       * containerId is used throughout container child components.
       * Class based rollup happens on an attribute level so joining all containers by `_`.
       *
       * @returns {boolean}
       */
      containerId() {
        return this.planDesignIsPlan
          ? this.container.id
          : this.info.container_info.map(({ id }) => id).join('_');
      },
      showDeviations: {
        /**
         * Show Deviations only required for the proposal document.
         * Determine if inputs are highlighted.
         *
         * @returns {boolean}
         */
        get() {
          return this.highlightedMap[this.containerId];
        },
        /**
         * toggles the boolean value of the appropriate highlighted container
         */
        set() {
          this.$set(
            this.highlightedMap,
            [this.containerId],
            !this.highlightedMap[this.containerId],
          );
        },
      },
      /**
       * Returns the lowest common denominator for subtype colspans.
       * Class-based products do return subtypes but I'll fix that in another PR. https://watchtower.atlassian.net/browse/CPV-732
       *
       * @returns {object}
       */
      subtypeColspan() {
        return this.planDesignIsPlan
          ? this.subtypeColspans[this.containerId.toString()]
          : this.subtypeColspans.classBased;
      },
      /**
       * Allows the user to save the list of predefined values
       *
       * @returns {boolean}
       */
      canSavePredefinedValues() {
        return !!Object.keys(this.userPreferredPlanDesignValues).length;
      },
    },
    methods: {
      ...mapActions(usePlanDesignStore, { storeCopyPlanDesign: 'copyPlanDesign' }),
      /**
       * Send anlytics when user focuses on the Copy From select.
       */
      copyPlanDesign() {
        this.storeCopyPlanDesign({
          productState: this.productState,
          productId: this.productId,
          sourceId: this.sourceId,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
section {
  position: relative;
}

.container-attributes {
  max-width: $max-width;
  margin: 0 auto;
}

.warning-message {
  margin-top: 33px !important; // stylelint-disable-line
  max-width: 862px;
}

.table-display-options {
  margin: {
    left: 30px;
    right: 30px;
  }
}

.quote-select {
  width: 191px;
}

:deep() {
  .el-checkbox {
    justify-content: flex-end;
  }

  .select-copy-from + .el-form-item--medium {
    margin-right: 35px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-form--label-right {
    display: flex;

    label: {
      display: inline-block;
    }
  }
}

.highlight-dropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  :deep() {
    .el-select {
      width: 240px;
    }

    .el-icon-circle-close {
      color: var(--tf-red);
    }
  }
}

.save-default-values-btn {
  margin-bottom: 20px;
}

.lower-button {
  margin: 0;
}
</style>
